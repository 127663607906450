export const links = Object.freeze({
  //public
  "countries": "api/list/countries",
  "contractors-list": "api/list/contractors-list",
  //dropdown
  "departments": "/api/list/departments",
  "brands": "api/list/brands",
  "models": "api/list/models",
  "main-areas" : "/api/list/fire-main-area",
  "sub-areas" : "/api/list/fire-sub-area",
  "floors": "api/list/floors",
  "spare-parts-categories": "/api/list/spare-parts-categories",
  "suppliers-list": "/api/list/suppliers",
  "duration-types": "/api/list/duration-types",
  //roles && permissions
  "roles": "/api/list/roles",
  //user module
  "users-emails": "/api/list/users-emails",
  "team": "/api/list/users",
  //fire system list
  "fire-systems": "/api/list/fire-systems",
  "fire-systems-list": "/api/list/fire-systems/list",
  "fire-systems-types": "/api/list/fire-systems/types",
  "fire-systems-columns": "/api/list/fire-systems/columns",
  //
  "cpanel-list": "/api/list/control-panel",
  "cabinets-list": "/api/list/cabinets",
  "fire-hoses": "/api/list/fire-hoses",
  "fire-extinguishers": "/api/list/fire-extinguishers",
  //daily logs
  "logs-types": "/api/list/logs/types",
  "panel-fault-logs": "/api/list/logs/panel-fault",
  "fire-alarm-logs": "/api/list/logs/fire-alarm",
  "mechanical-fault-logs": "/api/list/logs/mechanical-fault",
  "fire-fighting-logs": "/api/list/logs/fire-fighting",
  "fault-reason-logs": "/api/list/logs/fault-reason",
  //spare parts
  "spare-parts": "/api/list/spare-parts",
  //maintenance actions
  "general-visual-tours": "/api/list/general-visual-tours",
  "inspection-schedule-durations" : '/api/list/inspection-schedule/durations',
  "inspection-schedule-fire-systems" : '/api/list/inspection-schedule/fire-systems',
  "preventive-maintenance-status" : '/api/list/preventive-maintenance/status-list'
  // "panel-faults-list": "/api/list/fault-reason-logs?type=panel",
  // "mechanical-faults-list": "/api/list/fault-reason-logs?type=mechanical",
  // "logs": "/api/list/logs",
});