import { createSlice } from "@reduxjs/toolkit"

export const maintenanceScheduleSlice = createSlice({
  name: 'maintenanceSchedule',
  initialState: {
    data: []
  },
  reducers: {
    addMaintenanceSchedulePeriod: (state, action) => {
      const { fire_system_type_id, duration_type_id } = action.payload;
      const system = state.data?.find(item => (item.duration_type_id === duration_type_id && item.fire_system_type_id === fire_system_type_id));
      if (!system) {
        state.data.push({ duration_type_id, columns: [], fire_system_type_id});
      }
    },
    removeMaintenanceSchedulePeriod: (state, action) => {
      const { fire_system_type_id, duration_type_id } = action.payload;
      const systemIndex = state.data.findIndex(item => (item.duration_type_id === duration_type_id && item.fire_system_type_id === fire_system_type_id));
      if (systemIndex !== -1 ) {
        state.data.splice(systemIndex, 1);
      }
    },
    addMaintenanceSchedulePeriodColumn: (state, action) => {
      const { fire_system_type_id, duration_type_id, id } = action.payload;
      const system = state.data.find(item => (item.fire_system_type_id === fire_system_type_id && item.duration_type_id === duration_type_id));
      if (system) {
        const { columns } = system;
        const col = columns?.find(col => (col.inspection_column_id === id));
        if (!col) {
          columns.push({
            fire_system_type_id,
            duration_type_id,
            inspection_column_id: id
          });
        }
      }
    },
    removeMaintenanceSchedulePeriodColumn: (state, action) => {
      const { fire_system_type_id, duration_type_id, id } = action.payload;
      const system = state.data.find(item => (item.fire_system_type_id === fire_system_type_id && item.duration_type_id === duration_type_id));
        if (system) {
          system.columns = system.columns.filter(col => col.inspection_column_id !== id);
        }
    },
    resetMaintenanceSchedulePeriodColumn: (state, action) => {
      // const { fire_system_type_id, period } = action.payload;
      // const system = state.data.find(item => item.fire_system_type_id === fire_system_type_id);
      // if (system) {
      //   const schedule = system.schedules.find(sch => sch.type === period);
      //   if (schedule) {
      //     schedule.columns = [];
      //   }
      // }
    },
    resetMaintenanceScheduleData: (state, action) => {
      state.data = Array.isArray(action.payload) ?  action.payload : [];
    }
  }
});

export const { 
  addMaintenanceSchedulePeriod,
  removeMaintenanceSchedulePeriod,
  addMaintenanceSchedulePeriodColumn,
  removeMaintenanceSchedulePeriodColumn,
  resetMaintenanceSchedulePeriodColumn,
  resetMaintenanceScheduleData
} = maintenanceScheduleSlice.actions

export default maintenanceScheduleSlice.reducer;