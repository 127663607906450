import { createSlice } from "@reduxjs/toolkit"

export const preventiveSlice = createSlice({
  name: 'preventive',
  initialState: {
    data: {}
  },
  reducers: {
    updateInitialPreventiveStateData: (state, action) => {

      let data = {...state.data};
      const intialData = action.payload || []
      intialData?.map(({preventiveMaintenancesColumns , id})=>{
        if(preventiveMaintenancesColumns?.[0]){
          if (!data[id]) {
            data[id] = {};
          }
          if (!data[id].columns) {
            data[id].columns = {};
          }
          const { status , reason = null , spareParts = [] , columns } = preventiveMaintenancesColumns?.[0]
            data[id]['columns'] = columns
            data[id]['reason'] = reason
            data[id]['spareParts'] = spareParts
            data[id]['status'] = status
        }else {
          data[id] = {}
        }
      })
      state.data = data;
    },
    updatePreventiveValues: (state, action) => {
      let data = {...state.data};
      const { id, values } = action.payload;
      if (!data[id]) {
        data[id] = {};
      }
      //
      values.forEach(({key, value}) => {
        data[id][key] = value;
      });
      //
      state.data = data;
    },
    updateColumnStatus: (state, action) => {
      const { id, column, value } = action.payload;
      let oldState = { ...state.data };

      if (!oldState[id]) {
        oldState[id] = { columns: {} };
      }

      if (!oldState[id].columns) {
        oldState[id].columns = {};
      }

      if (value != undefined) {
        oldState[id].columns[column] = value;
      } else {
        delete oldState[id].columns[column];
      }

      state.data = oldState;
    },
    resetPreventiveValues: (state) => {
      state.data = {};
    },
  },
  extraReducers: (builder) => {
    // Add extra reducers here if needed
  },
});

export const { 
  updateInitialPreventiveStateData, 
  updatePreventiveValues, 
  updateColumnStatus, 
  resetPreventiveValues
} = preventiveSlice.actions

export default preventiveSlice.reducer;